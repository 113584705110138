import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

import SEO from "../components/Seo";
import Nav from "../components/Nav";
import ReusableHero from "../components/ReusableHero";
import ReusableFooter from "../components/ReusableFooter";
import OurWork from "../components/Ourworks";
import AccomplishmentSlider from "../components/AccomplishmentSlider";
import ClientTestimonials from "../components/ClientTestimonials";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import StaticLogo from "../components/StaticLogo";
import MobileNav from "../components/MobileNav";
import Slideshow from "../components/Slideshow";

import fblogo from "../images/fbads.png";
import digimark from "../images/fbadsbanner.png";

import digitalMarketing from "../images/offers/digital_marketing.svg";
import eCommerce from "../images/offers/e-commerce.svg";
import facebookAds from "../images/offers/facebookads.svg";
import seo from "../images/offers/seo.svg";

import barchart from "../images/features/barchart.png";
import monitor from "../images/features/monitor.png";
import medal from "../images/features/medal.png";
import brush from "../images/features/brush.png";
import google from "../images/google.jpg";

import "../styles/digital_marketing.css";
import ReusableRanking from "../components/ReusableRanking";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Facebook_advertising({ location }) {
  const headerText = "PAID";
  const subheader = "ADVERTISING";
  const paragraph =
    "We design targeted ad campaigns on platforms like Google, Facebook, and Instagram to drive traffic, increase conversions, and scale your business.";
  const text =
    "Do you have a project in mind? Get An Estimate for your Digital Marketing Campaign";

  const rankingArray = [
    {
      num: 1,
      header: "Growth-Focused Campaigns",
      para: "We increase customer acquisition and revenue through growth-focused campaigns across Facebook, Instagram and Google. We use information and automation to optimize campaigns and improve ROI.",
      classes: "rrfirstclass",
      img: brush,
    },
    {
      num: 2,
      header: "Focus Ads",
      para: "We provide Focus Ads and budgeting for geographic locations that fit your products or services. Geographic and demographic targeting is a cost-effective way to drive engagement and sales for your business.",
      classes: "rrsecondclass",
      img: barchart,
    },
    {
      num: 3,
      header: "Target Your Audience",
      para: "As with all great digital strategies, we focus on your audience when developing marketing materials so we can speak to your audience in a meaningful and effective way.",
      classes: "rrfirstclass",
      img: monitor,
    },
    {
      num: 4,
      header: "High Income",
      para: "We’ll help you increase targeted leads to give you a positive ROI on your advertising investment.",
      classes: "rrsecondclass",
      img: medal,
    },
  ];

  const isBrowser = typeof window !== "undefined";

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (isBrowser) {
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
    }

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // useEffect(() => {
  //   let dmtl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".digimark",
  //       start: "top bottom",
  //     },
  //   });

  //   dmtl
  //     .fromTo(
  //       ".firstfb",
  //       {
  //         x: -100,
  //         opacity: 0,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".next-gen",
  //       {
  //         x: -200,
  //         opacity: 0,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".rocketpic",
  //       {
  //         x: 200,
  //         opacity: 0,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 },
  //       ">"
  //     )
  //     .fromTo(
  //       ".ecomtxt",
  //       {
  //         x: -200,
  //         opacity: 0,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".ecomlearn",
  //       {
  //         x: -200,
  //         opacity: 0,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       "#ecomlearn",
  //       {
  //         opacity: 0,
  //       },
  //       { duration: 0.25, opacity: 1 }
  //     );
  // }, []);

  // useEffect(() => {
  //   let cardstl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".whatwedo",
  //       start: "bottom center",
  //     },
  //   });

  //   cardstl
  //     .fromTo(
  //       ".whatwedo",
  //       {
  //         opacity: 0,
  //         y: -100,
  //       },
  //       { duration: 0.25, y: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".ofc1",
  //       {
  //         opacity: 0,
  //         x: 100,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".ofc2",
  //       {
  //         opacity: 0,
  //         x: 100,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".ofc3",
  //       {
  //         opacity: 0,
  //         x: 100,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".ofc4",
  //       {
  //         opacity: 0,
  //         x: 100,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     )
  //     .fromTo(
  //       ".ofc5",
  //       {
  //         opacity: 0,
  //         x: 100,
  //       },
  //       { duration: 0.25, x: 0, opacity: 1 }
  //     );
  // }, []);

  return (
    <Box className="main" overflowX="hidden">
      <SEO />
      {width > 1280 ? (
        <Nav loc={location} page="/facebook_advertising" main="/marketing" />
      ) : (
        <MobileNav main="/facebook_advertising" />
      )}
      <Box>
        <ReusableHero
          header={headerText}
          subheader={subheader}
          paragraph={paragraph}
          image={digimark}
          imageClass="digitalmarkbanner"
        />
        <Flex
          pt="50px"
          px="5%"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            className="digimark"
            width={[
              "100%", // 0-30em
              "100%", // 30em-48em
              "100%",
              "100%", // 48em-62em
              "50%", // 62em+
            ]}
          >
            <Text as="h3" fontSize="5xl" className="firstfb">
              Paid Ads
            </Text>
            <Text as="h3" fontSize="5xl" className="next-gen">
              <span>Management</span>
            </Text>
            <Text as="p" fontSize="xl" className="ecomtxt">
              Paid advertising on platforms like Google, Facebook, Instagram,
              and others offers unparalleled reach and effectiveness. Research
              shows that millions of active users engage with these platforms
              daily, providing businesses with vast opportunities to connect
              with their target audiences.
              <br />
              <br />
              Many small to medium businesses have recognized the advantages of
              leveraging paid ads in their marketing strategies. By building an
              organic following and running targeted ad campaigns, businesses
              can increase revenue, enhance visibility, and reach their consumer
              market in a cost-effective way.
            </Text>
            <Flex
              justifyContent={[
                "center",
                "center",
                "center",
                "center",
                "flex-start",
                "flex-start",
              ]}
            >
              <LearnMoreButton id="ecomlearn" link="/contact" />
            </Flex>
          </Box>

          <Flex
            width={[
              "100%", // 0-30em
              "100%", // 30em-48em
              "100%",
              "100%", // 48em-62em
              "40%", // 62em+
            ]}
            pt="10px"
            justifyContent="center"
            display={["none", "none", "none", "flex", "flex"]}
          >
            <img
              src={fblogo}
              alt="rocketpc.svg"
              className="rocketpic"
              style={{ borderRadius: "10px", width: "550px", height: "400px" }}
            />
          </Flex>
        </Flex>

        {/* <Flex
          mt="70px"
          px={["10px", "20px", "60px", "103px"]}
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            width={[
              "100%", // 0-30em
              "100%", // 30em-48em
              "100%",
              "100%", // 48em-62em
              "55%", // 62em+
            ]}
            pt="10px"
            px="20px"
            justifyContent="center"
            display={["none", "none", "none", "flex", "flex"]}
          >
            <img src={google} alt="rocketpc.svg" className="rocketpic" />
          </Flex>
          <Box
            className="digimark"
            width={[
              "100%", // 0-30em
              "100%", // 30em-48em
              "100%",
              "100%", // 48em-62em
              "40%", // 62em+
            ]}
            mx="20px"
            mt="30px"
          >
            <Text as="h3" fontSize="5xl" className="firstecom">
              <span>Google Ads</span> <br />
              Management
            </Text>
            <Text as="p" fontSize="xl" className="ecomtxt">
              Google is head-and-shoulders above any other website as the most
              used search engine on the internet. It has transcended itself from
              a brand to a verb, which means to search and obtain information.
              If you don’t believe us, Google it!
              <br />
              <br />
              Google handles over 5 billion searches per day. By utilising
              Google Ads, your business will be displayed to people who are
              actively seeking out the products and services you provide. PPC
              means you only pay each time your ad is clicked by your
              prospective customers, meaning you only pay for results!
              Successful businesses recognise the benefits of Google Ads PPC in
              sending high-value traffic to their website.
            </Text>
            <Flex
              justifyContent={[
                "center",
                "center",
                "center",
                "center",
                "flex-start",
                "flex-start",
              ]}
            >
              <LearnMoreButton id="ecomlearn" link="/contact" />
            </Flex>
          </Box>
        </Flex> */}

        <Flex
          flexDirection="row"
          flexWrap="noWrap"
          w="100%"
          className="whatwedo"
          justifyContent="center"
          alignItems="center"
          pt="123px"
        >
          <Box textAlign="center">
            <h3>Ascend Group Media As Your Paid Ads Agency</h3>
          </Box>
        </Flex>

        <Flex
          pt="50px"
          justifyContent="center"
          flexWrap="wrap"
          margin="auto"
          maxW="1400px"
        >
          <Box className="biggerCards ofc1">
            <img src={digitalMarketing} alt="digitalmarketing.svg" />
            <h3>Designing &amp; Development</h3>
            <p>
              We will help you design and develop your marketing campaigns and
              ad placements across various platforms to:
              <br />
              <ul>
                <li>Maximize engagement with targeted paid ads.</li>
                <li>
                  Drive high-quality traffic from these platforms to your
                  website.
                </li>
                <li>
                  Build brand loyalty through effective ad campaigns that turn
                  customers into brand advocates.
                </li>
                <li>
                  Optimize the conversion rate of your paid advertising efforts
                  for maximum ROI
                </li>
              </ul>
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box>
          <Box className="biggerCards ofc2">
            <img src={eCommerce} alt="digitalmarketing.svg" />
            <h3>Industry Analysis</h3>
            <p>
              <ul>
                <li>
                  Find the top competitors in the industry (in terms of audience
                  size and engagement) and study their best practices.
                </li>
                <li>
                  Research the marketing content and communication methods that
                  are proven effective in your industry.
                </li>
              </ul>
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box>

          {/* <Box className="biggerCards ofc3">
            <img src={facebookAds} alt="digitalmarketing.svg" />
            <h3>Account and Campaign Creation</h3>
            <p>
              <ul>
                <li>
                  Create a social media account that is visually appealing and
                  engaging.
                </li>
                <li>
                  Create organic social posts with content that encourages high
                  audience engagement and interaction.
                </li>
                <li>
                  Create high-quality ad materials for paid social campaigns
                  targeted towards your potential consumers.
                </li>
              </ul>
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box> */}
          <Box className="biggerCards2 ofc4">
            <img src={facebookAds} alt="digitalmarketing.svg" />
            <h3>Account and Campaign Creation</h3>
            <p>
              <ul>
                <li>
                  3T1R - Track, Test, Tweak, Repeat. Marketing should be
                  data-driven and measurable to properly gauge effectiveness and
                  improvement. Our marketing results will be the basis for
                  future activities and campaigns.
                </li>
                <li>
                  Track audience reach, engagement, click-through, leads, and
                  the ROI of paid campaigns.
                </li>
                <li>
                  Manage campaign parameters and analyse for performance
                  improvements.
                </li>
                <li>
                  Report engagement, leads, and sales derived from paid social
                  campaigns and use that data to improve performance on future
                  campaigns.
                </li>
              </ul>
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box>
          {/* <Box className="biggerCards2 ofc5">
            <img src={seo} alt="digitalmarketing.svg" />
            <h3>Other Marketing Strategies</h3>
            <p>
              <ul>
                <li>
                  Collect data on new and prospective customers to create a
                  market profile of your customers.
                </li>
                <li>
                  Increase your audience through links to other social media
                  sites such as Instagram.
                </li>
                <li>Automate e-mail campaigns.</li>
              </ul>
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box> */}
          {/* <Box className="biggerCards2 ofc4">
            <img src={digitalMarketing} alt="digitalmarketing.svg" />
            <h3>PPC Campaigns.</h3>
            <p>
              With Ascend Group Media’s team of experts, your PPC campaign will
              be strategically designed to drive results and deliver quality
              leads and conversions. Consumers will see and be encouraged to
              click on your ads.
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box> */}

          {/* <Box className="biggerCards2 ofc4">
            <img src={eCommerce} alt="digitalmarketing.svg" />
            <h3>PPC Results</h3>
            <p>
              We will assist you to set up the right PPC campaign and get the
              results that matter to you – more website visits, more phone calls
              and direct messages, more leads, and more sales are all attainable
              with PPC marketing.
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box> */}

          {/* <Box className="biggerCards2 ofc4">
            <img src={facebookAds} alt="digitalmarketing.svg" />
            <h3>PPC Tracking</h3>
            <p>
              To know if your ad placement is working, it should be measurable.
              We will provide you with transparent and easy-to-understand PPC
              results so you can track your ROI.
            </p>
            <Flex justifyContent="center" alignItems="center">
              <button
                className="bizbut"
                onClick={() => {
                  window.location.assign("/contact");
                }}
              >
                BUSINESS ENQUIRY
              </button>
            </Flex>
          </Box> */}
        </Flex>

        {/* <Box
          pt="130px"
        >
          <h3
            className="digimarkfeatures"
          >
            DIGITAL MARKETING <span>FEATURES</span>
          </h3>
          <Box
            className="footerrule"
          >
            <hr />
          </Box>
        </Box>
        <Flex
          pt="80px"
          justifyContent="center"
          alignItems="center"
        > 
          <img src={features} alt="marketing_features.svg" />
        </Flex> */}

        <Flex
          pt="50px"
          justifyContent="center"
          flexWrap="wrap"
          margin="auto"
          maxW="1400px"
        >
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            px="5%"
          >
            <Box>
              <h3 className="digimarkfeatures">
                Why <span>Ascend Group Media</span>
              </h3>
            </Box>
            <Box p="20px">
              <p className="whyus">
                At Ascend Group Media, we specialize in increasing your brand’s
                visibility, expanding your fan base, and boosting overall
                engagement through strategic paid advertising campaigns. Our
                expertise spans across various digital marketing channels,
                ensuring optimized performance to drive growth in order size and
                number of orders for your business.
              </p>
            </Box>
          </Flex>
        </Flex>

        <ReusableRanking textArray={rankingArray} />
        {/* <OurWork /> */}
        <AccomplishmentSlider />
        <ClientTestimonials />
        {/* <StaticLogo /> */}
        <Slideshow />
        <ReusableFooter text={text} />
        <Footer />
      </Box>
    </Box>
  );
}
